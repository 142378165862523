import { transformIsoToHour } from '../../scheduler/transformers/transformIsoToHour';

export const transformSlotToNightDeliveryTime = ({
  dateTo,
  translation,
  locale,
  dateFrom,
}: {
  dateTo: string;
  translation: string;
  locale: string;
  dateFrom?: string;
}) => {
  const day = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
  }).format(new Date(dateTo));
  const dateToHour = transformIsoToHour(dateTo);
  const dateFromHour = dateFrom && transformIsoToHour(dateFrom);

  const dayWithCapitalizedLetter = day.charAt(0).toUpperCase() + day.slice(1);

  return dateFromHour
    ? `${dayWithCapitalizedLetter}, ${dateFromHour} - ${dateToHour}`
    : `${dayWithCapitalizedLetter}, ${translation} ${dateToHour}`;
};
