import { differenceInDays, startOfDay } from 'date-fns';

export const transformToRelativeDay = ({
  baseDate,
  targetDate,
  locale,
}: {
  baseDate: Date | string;
  targetDate: Date | string;
  locale: string;
}) => {
  const target = startOfDay(new Date(targetDate));
  const base = startOfDay(new Date(baseDate));

  return new Intl.RelativeTimeFormat(locale, {
    style: 'short',
    numeric: 'auto',
  }).format(differenceInDays(target, base), 'days');
};
