import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import { FormattedPrice } from '../FormattedPrice/FormattedPrice';

const asterisk = '*';

export const OldPrice: FC<{
  currencyCode: string;
  centAmount: number;
  color?: string;
  showAsterisk?: boolean;
}> = ({ centAmount, currencyCode, color, showAsterisk = true }) => (
  <span className={cn('line-through', color || 'text-gray-500')}>
    <FormattedPrice centAmount={centAmount} currencyCode={currencyCode} />
    {showAsterisk && asterisk}
  </span>
);
