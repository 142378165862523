import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import {
  closeLocationSchedulerModal,
  openLocationSchedulerModal,
} from '@/store/slices/locationScheduler';
import { selectSlot } from '@/store/slices/slotsSlice';
import { useAppDispatch } from '@/store/storeHooks';

export const useLocationModal = () => {
  const dispatch = useAppDispatch();
  const { data } = useShippingAddress();

  const openLocationModal = () => {
    const panel = data?.postalCode ? 'MainPanel' : 'AddressPanel';

    dispatch(openLocationSchedulerModal(panel));
  };

  const closeLocationModal = () => {
    dispatch(selectSlot(null));
    dispatch(closeLocationSchedulerModal());
  };

  return {
    openLocationModal,
    closeLocationModal,
  };
};
