import { ReactElement } from 'react';

import { cn } from '@/core/ui/utils';

type NumberOfBadges = number | 'no-limit';

export const ProductBadgesWrapper = ({
  badges,
  direction,
  maxNumberOfBadgesOnDesktop,
  maxNumberOfBadgesOnMobile,
  slim,
}: {
  badges: ReactElement[];
  direction: 'column' | 'row';
  maxNumberOfBadgesOnDesktop: NumberOfBadges;
  maxNumberOfBadgesOnMobile: NumberOfBadges;
  slim?: boolean;
}) => (
  <div
    className={cn(
      'flex',
      'gap-y-1',
      slim ? 'gap-x-1' : 'gap-x-2.5',
      direction === 'column' ? 'flex-col' : 'flex-row flex-wrap'
    )}
  >
    {badges.map((badge, i) => {
      const mobileClassName =
        maxNumberOfBadgesOnMobile === 'no-limit' ||
        i < maxNumberOfBadgesOnMobile
          ? 'block'
          : 'hidden';

      const desktopClassName =
        maxNumberOfBadgesOnDesktop === 'no-limit' ||
        i < maxNumberOfBadgesOnDesktop
          ? 'sm:block'
          : 'sm:hidden';

      return (
        <div
          className={cn(
            direction === 'column' && 'mr-auto',
            mobileClassName,
            desktopClassName
          )}
          key={i}
        >
          {badge}
        </div>
      );
    })}
  </div>
);
