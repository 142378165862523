import { publicEnv } from '@lib/env/public-env-vars';

declare global {
  interface Window {
    google_tag_manager?: {
      [key: string]: {
        dataLayer?: {
          reset: () => void;
        };
      };
    };
  }
}

export const pushGtmEvent = (event: Record<string, unknown>) => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push(event);

    window.google_tag_manager?.[
      `${publicEnv.NEXT_PUBLIC_GTM_CONTAINER_ID}`
    ]?.dataLayer?.reset();
    window.google_tag_manager?.[
      `${publicEnv.NEXT_PUBLIC_GTM_KEY}`
    ]?.dataLayer?.reset();
  }
};
