import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import { differenceInMinutes, isBefore } from 'date-fns';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';

import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { selectCoordinatesFromAddress } from '@/modules/checkout/selectors/selectCoordinatesFromAddress';
import { useEmitTimeSlotChosen } from '@/modules/scheduler/hooks/useEmitTimeSlotChosen';
import { useDeliveryScheduleSlotsQuery } from '@/modules/scheduler/queries/DeliveryScheduleSlots.delio.generated';
import { deliveryScheduleSlots } from '@/modules/scheduler/selectors/deliveryScheduleSlotsMock';
import { selectedSlotSelector } from '@/store/selectors/slotsSelectors';
import { openLocationSchedulerModal } from '@/store/slices/locationScheduler';
import { selectSlot } from '@/store/slices/slotsSlice';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { ERROR_CODES } from '../constants/errorCodes';

import { useCurrentCart } from './useCurrentCart';
import { useUpdateCurrentCartMutation } from './useUpdateCurrentCartMutation';

export const useDeliveryScheduleSlots = ({
  slotsNetworkPolicy,
}: {
  slotsNetworkPolicy?: WatchQueryFetchPolicy;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { pathname, replace } = useRouter();

  const { data: shippingAddress, loading: shippingAddressLoading } =
    useShippingAddress();
  const coordinates = selectCoordinatesFromAddress(shippingAddress);
  const { data: currentCartData, loading: currentCartLoading } =
    useCurrentCart();
  const emitTimeSlotChosen = useEmitTimeSlotChosen();
  const currentCartSlot = currentCartData?.currentCart.deliveryScheduleSlot;
  const [updateCurrentCart, updatingCartResult] =
    useUpdateCurrentCartMutation();

  const selectedSlot = useAppSelector(selectedSlotSelector);

  const handleDeliverScheduleSlotError = useCallback(
    (err: unknown) => {
      if (err instanceof ApolloError) {
        const [error] = err.graphQLErrors;

        if (error?.message === ERROR_CODES.ADDRESS_OUTSIDE_DELIVERY_AREA) {
          if (pathname !== '/') {
            return replace('/');
          }
          dispatch(openLocationSchedulerModal('AddressPanel'));
          toast.error(t('Destination address outside of delivery area'));
          return;
        }
      }
      throw err;
    },
    [dispatch, pathname, replace, t]
  );

  const {
    data: deliveryScheduleSlotsData,
    loading: deliverySlotsLoading,
    error: deliveryScheduleSlotsError,
  } = useDeliveryScheduleSlotsQuery(
    coordinates
      ? {
          variables: { coordinates },
          fetchPolicy: slotsNetworkPolicy,
          onError: handleDeliverScheduleSlotError,
        }
      : { skip: true }
  );

  const availableDeliveryScheduleSlots = useMemo(
    () =>
      deliveryScheduleSlotsData?.deliveryScheduleSlots.filter(
        (el) =>
          el.available &&
          isBefore(new Date(), new Date(el.bookableUntil)) &&
          isBefore(new Date(), new Date(el.dateFrom))
      ),
    [deliveryScheduleSlotsData?.deliveryScheduleSlots]
  );

  const firstAvailableSlot = useMemo(
    () => availableDeliveryScheduleSlots?.[0],
    [availableDeliveryScheduleSlots]
  );

  const storedSlot = useMemo(
    () =>
      availableDeliveryScheduleSlots?.find(
        (el) =>
          el.dateFrom === currentCartSlot?.dateFrom &&
          el.dateTo === currentCartSlot?.dateTo
      ),
    [
      availableDeliveryScheduleSlots,
      currentCartSlot?.dateFrom,
      currentCartSlot?.dateTo,
    ]
  );

  const activeSlot = useMemo(
    () => selectedSlot || storedSlot,
    [selectedSlot, storedSlot]
  );

  const storeSelectedSlot = useCallback(
    async (slotDateFrom?: string, slotDateTo?: string) => {
      if (!currentCartData?.currentCart.id) return;
      const currentSlot = {
        dateFrom: slotDateFrom || selectedSlot?.dateFrom,
        dateTo: slotDateTo || selectedSlot?.dateTo,
      };

      if (!currentSlot.dateFrom || !currentSlot.dateTo) {
        return;
      }

      try {
        await updateCurrentCart({
          variables: {
            cartId: currentCartData.currentCart.id,
            actions: [
              {
                SetDeliveryScheduleSlot: {
                  deliveryScheduleSlot: {
                    dateFrom: currentSlot.dateFrom,
                    dateTo: currentSlot.dateTo,
                  },
                },
              },
            ],
          },
        });
        dispatch(
          selectSlot({
            dateFrom: currentSlot.dateFrom,
            dateTo: currentSlot.dateTo,
          })
        );
        emitTimeSlotChosen(
          {
            dateFrom: currentSlot.dateFrom,
            dateTo: currentSlot.dateTo,
          },
          deliveryScheduleSlots
        );
      } catch (err) {
        captureException(err);
        toast.error(
          t('Something went wrong, please refresh the page and try again.')
        );
      }
    },
    [
      currentCartData?.currentCart.id,
      dispatch,
      emitTimeSlotChosen,
      selectedSlot?.dateFrom,
      selectedSlot?.dateTo,
      t,
      updateCurrentCart,
    ]
  );

  const firstSlotBookableForMinutes = useMemo(
    () =>
      firstAvailableSlot
        ? differenceInMinutes(
            new Date(firstAvailableSlot.bookableUntil),
            new Date(),
            {
              roundingMethod: 'ceil',
            }
          )
        : null,
    [firstAvailableSlot]
  );

  const firstSlotBookableInThisHour = Boolean(
    firstSlotBookableForMinutes &&
      firstSlotBookableForMinutes < MINUTES_IN_HOUR &&
      firstSlotBookableForMinutes > 0
  );

  return {
    isStoredSlotLoading:
      shippingAddressLoading || currentCartLoading || deliverySlotsLoading,
    storedSlot,
    firstAvailableSlot,
    deliveryScheduleSlotsLoading:
      shippingAddressLoading || deliverySlotsLoading,
    availableDeliveryScheduleSlots,
    storeSelectedSlot,
    isStoringSlot: updatingCartResult.loading,
    deliveryScheduleSlotsError,
    activeSlot,
    firstSlotBookableInThisHour,
    firstSlotBookableForMinutes,
  };
};

const MINUTES_IN_HOUR = 60;
