import { ApolloError } from '@apollo/client';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import toast from 'react-hot-toast';

import { useUpdateCurrentCartMutation } from '@/core/hooks/useUpdateCurrentCartMutation';
import { cn } from '@/core/ui/utils';

import { AddedLineItem } from '../types';

export const useAddLineItemToMyCart = () => {
  const { t } = useTranslation('product');

  const handleError = useCallback(
    (error: unknown) => {
      if (error instanceof ApolloError) {
        const [err] = error.graphQLErrors;

        if (err?.extensions?.code === 'MaxResourceLimitExceeded') {
          return toast.error(<ErrorToastMessage />);
        }

        if (
          err?.message === 'MAX_LINE_ITEM_AMOUNT_LIMIT_EXCEEDED' ||
          err?.message === 'MAX_WEIGHT_PER_ORDER_EXCEEDED'
        ) {
          return toast.error(t('The weight of the products has been exceeded'));
        }
      }

      return toast.error(t('Cannot add product to the cart'));
    },
    [t]
  );

  const [updateCurrentCart, addLineItemToMyCartResult] =
    useUpdateCurrentCartMutation();

  const addLineItemToMyCart = useCallback(
    async (
      lineItems: AddedLineItem[],
      cart: {
        version: number;
        id: string;
      }
    ) => {
      const result = await updateCurrentCart({
        variables: {
          cartId: cart.id,
          actions: lineItems.map(({ sku, quantity }) => ({
            AddLineItem: { quantity: quantity ?? 1, sku },
          })),
        },
        onError: handleError,
      });
      return result.data?.updateCart;
    },
    [handleError, updateCurrentCart]
  );

  return [addLineItemToMyCart, addLineItemToMyCartResult] as const;
};

const ErrorToastMessage = () => (
  <div className={cn('w-[300px]')}>
    <Trans
      ns="product"
      i18nKey="<BoldedText>Could not add product to basket.</BoldedText><br>Your order is too large, reduce the quantity and try again."
      components={{
        BoldedText: <span className={cn('font-bold')} />,
      }}
    />
  </div>
);
