import { PRODUCT_TAG_KEYS } from '@/config/consts';
import { logger } from '@/config/logger';
import { Product } from '@/modules/product/queries/types';
import { FC } from '@/types/common';
import { sup } from '@lib/sup/sup';
import { Media } from '@lib/theme/components/Media';

import { ProductBadge } from './components/ProductBadge';
import { ProductBadgesWrapper } from './components/ProductBadgesWrapper';

export const ProductBadgesList: FC<ProductBadgesListProps> = ({
  tags,
  direction,
  maxNumberOfBadgesOnDesktop,
  maxNumberOfBadgesOnMobile,
  showOnlyIcon,
  showOnlyIconOnMobile,
  unfoldOnHover,
  slim,
  color,
}) => {
  const tagBadges = tags
    ? tags.map(({ key: tagKey }) => {
        const isValidKey = productTagStruct.is(tagKey);

        if (!isValidKey) {
          logger.error('Unsupported key of a product tag', tagKey);
          return null;
        }

        return (
          <>
            <Media greaterThanOrEqual="xl">
              <ProductBadge
                key={tagKey}
                variant={tagKey}
                showOnlyIcon={showOnlyIcon}
                unfoldOnHover={unfoldOnHover}
                color={color}
              />
            </Media>
            <Media
              greaterThanOrEqual={showOnlyIconOnMobile ? 'md' : undefined}
              lessThan="xl"
            >
              <ProductBadge
                key={tagKey}
                variant={tagKey}
                showOnlyIcon={showOnlyIcon}
                color={color}
              />
            </Media>
            {showOnlyIconOnMobile && (
              <Media lessThan="md">
                <ProductBadge
                  key={tagKey}
                  variant={tagKey}
                  showOnlyIcon={showOnlyIcon || showOnlyIconOnMobile}
                  color={color}
                />
              </Media>
            )}
          </>
        );
      })
    : [];

  const appliedBadges: JSX.Element[] = tagBadges.filter(
    (badge): badge is JSX.Element => Boolean(badge)
  );

  if (appliedBadges.length === 0) return null;

  return (
    <ProductBadgesWrapper
      maxNumberOfBadgesOnDesktop={maxNumberOfBadgesOnDesktop}
      maxNumberOfBadgesOnMobile={maxNumberOfBadgesOnMobile}
      direction={direction}
      badges={appliedBadges}
      slim={slim}
    />
  );
};

const productTagStruct = sup.enums(PRODUCT_TAG_KEYS);

type ProductBadgesListProps = {
  // Undefined is explicit here, so we won't forget about passing the props
  tags: Product['attributes']['tags'] | undefined;
  direction: Direction;
  maxNumberOfBadgesOnDesktop: NumberOfBadges;
  maxNumberOfBadgesOnMobile: NumberOfBadges;
  showOnlyIcon?: boolean;
  showOnlyIconOnMobile?: boolean;
  unfoldOnHover?: boolean;
  slim?: boolean;
  color?: 'primary' | 'promoted';
};

type NumberOfBadges = number | 'no-limit';
type Direction = 'column' | 'row';
