import { useCallback } from 'react';

import { sendTimeSlotChosen } from '@/modules/analytics/eventHandlers/sendTimeSlotChosen';
import { isTruthy } from '@lib/api/is-truthy';

import { DeliveryScheduleSlotsQuery } from '../queries/DeliveryScheduleSlots.delio.sdk.generated';

export const useEmitTimeSlotChosen = () =>
  useCallback(
    (
      currentSlot: { dateFrom: string; dateTo: string },
      allAvailableSlots: DeliveryScheduleSlotsQuery['deliveryScheduleSlots']
    ) => {
      const { dateFrom, dateTo } = currentSlot;

      const otherAvailableSlots = allAvailableSlots
        .filter(isTruthy)
        .filter((slot) => slot.dateFrom !== dateFrom && slot.dateTo !== dateTo);

      sendTimeSlotChosen({
        dateFrom: formatDate(dateFrom),
        dateTo: formatDate(dateTo),
        otherAvailableSlots: otherAvailableSlots.map((slot) => ({
          dateFrom: formatDate(slot.dateFrom),
          dateTo: formatDate(slot.dateTo),
          bookableUntil: slot.bookableUntil,
        })),
      });
    },
    []
  );

const formatDate = (date: string) =>
  new Intl.DateTimeFormat('pl', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(new Date(date));
