import dynamic from 'next/dynamic';

import { cn } from '@/core/ui/utils';
import { BadgeVariant } from '@/modules/product/types/BadgeVariant';

const NoLactoseIcon = dynamic(
  async () => import('../images/productBadgeIcons/no-lactose-icon.svg'),
  { ssr: false }
);
const EcoIcon = dynamic(
  async () => import('../images/productBadgeIcons/eco-icon.svg'),
  { ssr: false }
);
const BioIcon = dynamic(
  async () => import('../images/productBadgeIcons/bio-icon.svg'),
  { ssr: false }
);
const HealthIcon = dynamic(
  async () => import('../images/productBadgeIcons/health-icon.svg'),
  { ssr: false }
);
const LocalIcon = dynamic(
  async () => import('../images/productBadgeIcons/local-icon.svg'),
  { ssr: false }
);
const NewIcon = dynamic(
  async () => import('../images/productBadgeIcons/new-icon.svg'),
  { ssr: false }
);

const VegeIcon = dynamic(
  async () => import('../images/productBadgeIcons/vege-icon.svg'),
  { ssr: false }
);
const VeganIcon = dynamic(
  async () => import('../images/productBadgeIcons/vegan-icon.svg'),
  { ssr: false }
);
const PopularIcon = dynamic(
  async () => import('../images/productBadgeIcons/popular-icon.svg'),

  { ssr: false }
);
const NoSugarIcon = dynamic(
  async () => import('../images/productBadgeIcons/no-sugar-icon.svg'),
  { ssr: false }
);
const NoGlutenIcon = dynamic(
  async () => import('../images/productBadgeIcons/no-gluten-icon.svg'),
  { ssr: false }
);

type BadgeIconProps = {
  variant: BadgeVariant;
  className?: string;
};

export const BadgeIcon = ({ variant, className }: BadgeIconProps) => {
  const Icon = createVariantIcon(variant);

  return <Icon className={cn(createVariantClasses(variant), className)} />;
};

const createVariantIcon = (variant: BadgeVariant) => {
  // Switch statement here allows to avoid:
  // - unhandled type, typescript yields an error when there is an unhandled case
  // - dash-case keys when using an object instead
  // - result for `never` type when using ifs instead
  switch (variant) {
    case 'bio':
      return BioIcon;
    case 'vege':
      return VegeIcon;
    case 'glutenfree':
      return NoGlutenIcon;
    case 'eco':
      return EcoIcon;
    case 'sugarfree':
      return NoSugarIcon;
    case 'lactosefree':
      return NoLactoseIcon;
    case 'vegan':
      return VeganIcon;
    case 'popular':
      return PopularIcon;
    case 'new':
      return NewIcon;
    case 'local':
      return LocalIcon;
    case 'health':
      return HealthIcon;
    default:
      throw new Error(`Unhandled variant: ${variant}`);
  }
};

const createVariantClasses = (variant: BadgeVariant): string | null => {
  // Switch statement here allows to avoid:
  // - unhandled type, typescript yields an error when there is an unhandled case
  // - dash-case keys when using an object instead
  // - result for `never` type when using ifs instead
  switch (variant) {
    case 'bio':
      return cn('text-badge-bio');
    case 'vege':
      return cn('text-badge-vegetarian');
    case 'glutenfree':
      return cn('text-badge-gluten-free');
    case 'eco':
      return cn('text-badge-eco');
    case 'sugarfree':
      return cn('text-badge-sugar-free');
    case 'lactosefree':
      return cn('text-badge-lactose-free');
    case 'vegan':
      return cn('text-badge-vegan');
    case 'popular':
      return cn('text-badge-popular');
    case 'new':
      return cn('text-badge-new');
    case 'local':
      return cn('text-badge-local');
    case 'health':
      return cn('text-badge-health');
    default:
      throw new Error(`Unhandled variant: ${variant}`);
  }
};
