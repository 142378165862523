import { createContext, useContext } from 'react';

type PaginatedProductListContextType = {
  categoryId: string;
};

export const PaginatedProductListContext =
  createContext<PaginatedProductListContextType | null>(null);

export const usePaginatedProductListContext = () =>
  useContext(PaginatedProductListContext);
