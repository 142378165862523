import { analytics } from '@/config/analytics';

type Slot = {
  dateFrom: string;
  dateTo: string;
};

export const sendTimeSlotChosen = (
  sendTimeSlotChosenPayload: SendTimeSlotChosenPayload
) => analytics.track('Time Slot Chosen', sendTimeSlotChosenPayload);

export type SendTimeSlotChosenPayload = {
  dateFrom: string;
  dateTo: string;
  otherAvailableSlots: Slot[] | null;
};
