export const deliveryScheduleSlots = [
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T09:00:00.000Z',
    dateTo: '2023-07-11T10:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T08:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T09:00:00.000Z',
    dateTo: '2023-07-11T11:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T08:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T10:00:00.000Z',
    dateTo: '2023-07-11T11:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T09:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T11:00:00.000Z',
    dateTo: '2023-07-11T13:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T10:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T15:00:00.000Z',
    dateTo: '2023-07-11T16:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T14:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-11T16:00:00.000Z',
    dateTo: '2023-07-11T17:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-11T15:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T07:00:00.000Z',
    dateTo: '2023-07-12T09:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T06:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T09:00:00.000Z',
    dateTo: '2023-07-12T10:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T08:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T09:00:00.000Z',
    dateTo: '2023-07-12T11:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T08:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T10:00:00.000Z',
    dateTo: '2023-07-12T11:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T09:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T11:00:00.000Z',
    dateTo: '2023-07-12T12:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T10:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T11:00:00.000Z',
    dateTo: '2023-07-12T13:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T10:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T15:00:00.000Z',
    dateTo: '2023-07-12T16:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T14:59:59.000Z',
  },
  {
    __typename: 'DeliveryScheduleSlot',
    dateFrom: '2023-07-12T16:00:00.000Z',
    dateTo: '2023-07-12T17:00:00.000Z',
    available: true,
    bookableUntil: '2023-07-12T15:59:59.000Z',
  },
] as const;
