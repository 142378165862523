import { analytics } from '@/config/analytics';

import { AIRSHIP_GROUP } from './constants';

const setProductsInCartGroup = (productsInCart: boolean) =>
  analytics.group(AIRSHIP_GROUP, {
    products_in_cart: productsInCart,
  });

export const addToProductsInCartGroup = () => setProductsInCartGroup(true);
export const removeFromProductsInCartGroup = () =>
  setProductsInCartGroup(false);
