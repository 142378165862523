import {
  DeliveryReward,
  Reward,
  ShippingDiscountReward,
} from '@/core/ui/components/CartProgressIndicator/types';

export function isGiftReward(reward: Reward): reward is DeliveryReward {
  return reward.__typename === 'DiscountReward';
}

export function isShippingDiscountReward(
  reward: Reward
): reward is ShippingDiscountReward {
  return reward.__typename === 'ShippingDiscountReward';
}

export const findReward = <T extends Reward>(
  rewards: Readonly<Reward[]> | undefined,
  guard: (reward: Reward) => reward is T
): T | undefined => rewards?.find(guard);
