import { CENTS_PER_UNIT } from '@/core/constants/price';
import { LineItem } from '@/core/types';

export const createGtmItem = (
  product: LineItem['product'],
  quantity: number = 1
) => {
  const { price, id, name } = product;
  const calculatedPrice =
    (price.discounted?.value ?? price.value).centAmount / CENTS_PER_UNIT;

  const discount = calculateDiscount(price);

  return {
    item_id: id,
    item_name: name,
    ...(discount
      ? {
          discount,
        }
      : {}),
    price: calculatedPrice,
    quantity,
  };
};

const calculateDiscount = (productPrice: LineItem['product']['price']) => {
  if (productPrice.discounted?.value) {
    return (
      (productPrice.value.centAmount -
        productPrice.discounted.value.centAmount) /
      CENTS_PER_UNIT
    );
  }

  return null;
};
