/** @type {Record<Breakpoint, number>} */
const breakpoints = {
  xs: 0,
  xsm: 375,
  sm: 640,
  md: 768,
  lmd: 860,
  slg: 980,
  lg: 1024,
  xl: 1280,
  lxl: 1440,
  '2xl': 1536,
};

/** @typedef {"xs" | "xsm" | "sm" | "md" | "lmd" | "slg" | "lg" | "xl" | "lxl" | "2xl"} Breakpoint */

export { breakpoints };
