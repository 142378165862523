import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

export const Badge: FCC<{
  children: ReactNode;
  variant?: 'default' | 'promoted' | 'disabled' | 'bundle';
}> = ({ children, variant = 'default' }) => (
  <div
    className={cn(
      'px-2.5',
      'py-1',
      'rounded-full',
      'text-xs',
      'box-content',
      'flex',
      'items-center',
      'text-white',
      'w-fit',
      variantStyles[variant]
    )}
  >
    {children}
  </div>
);

const variantStyles = {
  default: 'bg-action-primary-default',
  promoted: 'bg-surface-top',
  disabled: 'bg-action-primary-disabled text-text-default',
  bundle: 'bg-badge-surface-zestaw',
};
