import { useEffect } from 'react';

export const useHideChat = (hide: boolean): void => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.fcWidget) {
      if (hide) {
        window.fcWidget.hide();
      } else {
        window.fcWidget.show();
      }
    }
    return () => {
      window?.fcWidget?.show();
    };
  }, [hide]);
};
