import { createContext, useContext } from 'react';

type ProductsCarouselContextType = {
  title: string;
};

export const ProductsCarouselContext =
  createContext<ProductsCarouselContextType | null>(null);

export const useProductsCarouselContext = () => {
  const context = useContext(ProductsCarouselContext);

  return context;
};
