import type { Analytics } from '@segment/analytics-next';

import { analytics } from '@/config/analytics';
import { CENTS_PER_UNIT } from '@/core/constants/price';
import { pushGtmEvent } from '@/core/services/pushGtmEvent';
import { LineItem } from '@/core/types';

import { createGtmItem } from '../services/createGtmItem';
import { mapRouteToScreen } from '../services/mapRouteToScreen';
import { Screens } from '../types/Screens';

const version = '0.4';

type SendProductRemovedEventArgs = {
  analytics?: Analytics | null;
  cartId: string;
  lineItem: LineItem;
  freeDelivery: boolean;
  freeDeliveryMissingValue: number;
  route: string;
  attributionToken?: string;
  sectionName: string | null;
  categoryId: string | null;
};

export const sendProductRemovedEvent = ({
  cartId,
  lineItem,
  freeDelivery,
  freeDeliveryMissingValue,
  route,
  attributionToken,
  sectionName,
  categoryId,
}: SendProductRemovedEventArgs) => {
  const { product } = lineItem;

  const currency = product.price.value.currencyCode;
  const itemPrice =
    (product.price.discounted?.value.centAmount ??
      product.price.value.centAmount) / CENTS_PER_UNIT;

  const gtmPayload = {
    event: 'remove_from_cart',
    lite_section: sectionName,
    lite_category: categoryId,
    currency,
    value: itemPrice,
    items: [createGtmItem(lineItem.product)],
    brand: 'delio',
  };

  const payload: ProductRemovedSegmentPayload = {
    cart_id: cartId,
    product_id: lineItem.product.id,
    name: lineItem.product.name,
    price: itemPrice,
    action: 'minus',
    quantity: 1,
    quantity_total: lineItem.quantity,
    screen: mapRouteToScreen(route),
    version,
    currency,
    free_delivery: freeDelivery,
    free_delivery_missing_value: freeDeliveryMissingValue / CENTS_PER_UNIT,
    sku: lineItem.product.sku,
    attribution_token: attributionToken,
    brand: 'delio',
  };

  pushGtmEvent(gtmPayload);
  return analytics?.track('Product Removed', payload);
};

export type ProductRemovedSegmentPayload = {
  cart_id: string;
  product_id: string;
  name: string;
  price: number;
  action: 'minus';
  quantity: number;
  quantity_total: number;
  screen: Screens;
  version: string;
  currency: string;
  free_delivery: boolean;
  free_delivery_missing_value: number;
  sku: string;
  attribution_token?: string;
  brand: string;
};
