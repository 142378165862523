import { useCurrentCart } from '@/core/hooks/useCurrentCart';

import { selectLineItemBySku } from '../selectors/selectLineItemBySku';

export const useProductLineItem = (sku: string) => {
  const currentCartResult = useCurrentCart();
  const currentCart = currentCartResult.data?.currentCart;
  if (!currentCart) return;
  const productLineItem = selectLineItemBySku(currentCart, sku);
  return productLineItem;
};
