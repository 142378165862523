import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { selectCartItemTotal } from '@/core/selectors/selectCartItemTotal';
import { CurrentCart } from '@/core/types';
import {
  findReward,
  isGiftReward,
  isShippingDiscountReward,
} from '@/core/ui/components/CartProgressIndicator/helpers/findReward';
import { selectCartProgressIndicatorIsOpen } from '@/store/selectors/cartProgressIndicatorSelectors';
import {
  hideCartProgressIndicator as hideProgress,
  showCartProgressIndicator as showProgress,
  showReachFreeDeliveryMessage,
} from '@/store/slices/cartProgressIndicatorSlice';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

const notSupportedRoutes = [
  '/checkout',
  'cartSidebar=open',
  '/sign-in',
  '/sign-up',
  '/order-details',
  '/profile',
  '/cart',
];

export const useCartProgressIndicator = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isOpen = useAppSelector(selectCartProgressIndicatorIsOpen);

  const isNotSupportedRoute = notSupportedRoutes.some((route) =>
    router.asPath.includes(route)
  );

  const showCartProgressIndicator = useCallback(
    ({
      previousLineItems,
      newLineItems,
      rewards,
    }: {
      previousLineItems: CurrentCart['lineItems'];
      newLineItems: CurrentCart['lineItems'];
      rewards: CurrentCart['rewards'];
    }) => {
      const shippingReward = findReward(rewards, isShippingDiscountReward);
      const giftReward = findReward(rewards, isGiftReward);

      const shippingRewardMinimumValue =
        shippingReward?.minimumCartValue?.centAmount ?? 0;
      const giftRewardMinimumValue =
        giftReward?.minimumCartValue?.centAmount ?? 0;

      const prevTotal = selectCartItemTotal(previousLineItems);
      const currentTotal = selectCartItemTotal(newLineItems);

      const prevDiscountableTotal = selectCartItemTotal(
        previousLineItems?.filter(
          (item) => item.product.attributes.discountable
        )
      );
      const currentDiscountableTotal = selectCartItemTotal(
        newLineItems?.filter((item) => item.product.attributes.discountable)
      );

      // Check conditions for shipping rewards
      const hasReachedShippingReward =
        prevTotal < shippingRewardMinimumValue &&
        currentTotal >= shippingRewardMinimumValue;

      const hasLostShippingReward =
        prevTotal >= shippingRewardMinimumValue &&
        currentTotal < shippingRewardMinimumValue;

      const hasChangedAndBelowShippingReward =
        currentTotal < shippingRewardMinimumValue && currentTotal !== prevTotal;

      // Check conditions for gift rewards
      const hasReachedGiftReward =
        prevDiscountableTotal < giftRewardMinimumValue &&
        currentDiscountableTotal >= giftRewardMinimumValue;

      const hasLostGiftReward =
        prevDiscountableTotal >= giftRewardMinimumValue &&
        currentDiscountableTotal < giftRewardMinimumValue;

      const hasChangedAndBelowGiftReward =
        currentDiscountableTotal < giftRewardMinimumValue &&
        currentDiscountableTotal !== prevDiscountableTotal;

      // Handle shipping reward progress
      if (hasReachedShippingReward) {
        dispatch(showReachFreeDeliveryMessage(true));
        if (!isNotSupportedRoute) dispatch(showProgress());
        return;
      }

      if (isNotSupportedRoute) return;

      // Handle shipping reward lost or changed
      if (hasLostShippingReward || hasChangedAndBelowShippingReward) {
        dispatch(showProgress());
        return;
      }

      // Handle gift reward progress
      if (
        hasReachedGiftReward ||
        hasLostGiftReward ||
        hasChangedAndBelowGiftReward
      ) {
        dispatch(showProgress());
      }
    },
    [dispatch, isNotSupportedRoute]
  );

  const hideCartProgressIndicator = useCallback(() => {
    if (isOpen) {
      dispatch(hideProgress());
    }
  }, [dispatch, isOpen]);

  return {
    showCartProgressIndicator,
    hideCartProgressIndicator,
  };
};
