import { transformIsoToHour } from '../../scheduler/transformers/transformIsoToHour';
import { transformToRelativeDay } from '../../scheduler/transformers/transformToRelativeDay';

export const transformSlotToDayDeliveryTime = ({
  dateFrom,
  dateTo,
  locale,
}: {
  locale: string;
  dateFrom?: string;
  dateTo?: string;
}) => {
  if (!dateFrom || !dateTo) return '';

  const relativeDay = transformToRelativeDay({
    baseDate: new Date(),
    targetDate: dateFrom,
    locale,
  });

  const slotRelativeDay = relativeDay ? `${relativeDay}, ` : '';

  const hours = `${transformIsoToHour(dateFrom)} - ${transformIsoToHour(
    dateTo
  )}`;

  return `${slotRelativeDay}${hours}`;
};
