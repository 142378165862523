import { captureMessage } from '@sentry/nextjs';

import { analytics } from '@/config/analytics';
import { CENTS_PER_UNIT } from '@/core/constants/price';
import { pushGtmEvent } from '@/core/services/pushGtmEvent';
import { LineItem } from '@/core/types';
import { AddedLineItem } from '@/modules/cart/types';

import { createGtmItem } from '../services/createGtmItem';
import { mapRouteToScreen } from '../services/mapRouteToScreen';
import { Screens } from '../types/Screens';

type SendProductAddedEventArgs = {
  cartId: string;
  lineItems: readonly LineItem[];
  route: string;
  freeDelivery: boolean;
  freeDeliveryMissingValue: number;
  attributionToken?: string;
  addedLineItems: AddedLineItem[];
  sectionName: string | null;
  categoryId: string | null;
};

export type ProductAddedSegmentPayload = {
  cart_id: string;
  product_id: string;
  name: string;
  price: number;
  action?: 'plus';
  quantity: number;
  quantity_total: number;
  screen: Screens;
  sku: string;
  free_delivery: boolean;
  free_delivery_missing_value: number;
  version: string;
  currency: string;
  attribution_token?: string;
  brand: string;
};

const currentVersion = '0.5';

export const sendProductAddedEvent = async ({
  cartId,
  freeDelivery,
  freeDeliveryMissingValue,
  lineItems,
  route,
  attributionToken,
  addedLineItems,
  sectionName,
  categoryId,
}: SendProductAddedEventArgs) => {
  await Promise.all(
    addedLineItems.map(async ({ sku }) => {
      const lineItem = lineItems.find(({ product }) => product.sku === sku);
      if (!lineItem) {
        return captureMessage(
          `Could not send 'ProductAdded' event; could not find line item by sku ${sku} in cart ${cartId}`,
          'fatal'
        );
      }
      const { product } = lineItem;

      const currency = product.price.value.currencyCode;
      const itemPrice =
        (product.price.discounted?.value.centAmount ??
          product.price.value.centAmount) / CENTS_PER_UNIT;

      const gtmPayload = {
        event: 'add_to_cart',
        lite_section: sectionName,
        lite_category: categoryId,
        currency,
        value: itemPrice,
        items: [createGtmItem(lineItem.product)],
        brand: 'delio',
      };

      const payload: ProductAddedSegmentPayload = {
        cart_id: cartId,
        product_id: lineItem.product.id,
        name: lineItem.product.name,
        price: itemPrice,
        action: 'plus',
        quantity: 1,
        quantity_total: lineItem.quantity,
        screen: mapRouteToScreen(route),
        sku: lineItem.product.sku,
        free_delivery: freeDelivery,
        free_delivery_missing_value: freeDeliveryMissingValue / CENTS_PER_UNIT,
        version: currentVersion,
        currency: lineItem.product.price.value.currencyCode,
        attribution_token: attributionToken,
        brand: 'delio',
      };

      pushGtmEvent(gtmPayload);
      await analytics?.track('Product Added', payload);
    })
  );
};
