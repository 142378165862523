import { useTranslation } from 'next-i18next';

import { transformSlotToDayDeliveryTime } from '@/modules/order/transformers/transformSlotToDayDeliveryTime';
import { transformSlotToNightDeliveryTime } from '@/modules/order/transformers/transformSlotToNightDeliveryTime';
import { DelioBrandStatus } from '@/types/gqlCommon.common.generated';

export const useDeliveryTime = (
  status?: DelioBrandStatus | null,
  currentSlot?: { dateFrom: string; dateTo: string } | null,
  showBothDelioNiteHours?: boolean
) => {
  const { t, i18n } = useTranslation('common');

  if (!status || !currentSlot) return '';

  // TODO: Check what happens when the context is not AvailableN
  return status === DelioBrandStatus.AvailableNite
    ? transformSlotToNightDeliveryTime({
        dateFrom: showBothDelioNiteHours ? currentSlot.dateFrom : undefined,
        dateTo: currentSlot.dateTo,
        locale: i18n.language,
        translation: t('to', {
          ns: 'common',
        }),
      })
    : transformSlotToDayDeliveryTime({
        dateFrom: currentSlot.dateFrom,
        dateTo: currentSlot.dateTo,
        locale: i18n.language,
      });
};
