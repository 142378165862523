import { useTranslation } from 'next-i18next';

import { Badge } from '../Badge/Badge';

export const BundleBadge = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation('common');

  return (
    <Badge variant={disabled ? 'disabled' : 'bundle'}>{t('Bundle')}</Badge>
  );
};
