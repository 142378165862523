import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { cn } from '@/core/ui/utils';
import { BadgeIcon } from '@/modules/product/components/BadgeIcon';
import { BadgeVariant } from '@/modules/product/types/BadgeVariant';
import { FCC } from '@/types/common';

export const ProductBadge: FCC<{
  variant: BadgeVariant;
  showOnlyIcon?: boolean;
  unfoldOnHover?: boolean;
  color?: 'primary' | 'promoted';
}> = ({ variant, showOnlyIcon, unfoldOnHover, color }) => {
  const mapTagKeyToLabel = useMapTagKeyToLabel();
  const [isHovering, setIsHovering] = useState(false);

  const shouldUnfold = isHovering && unfoldOnHover && showOnlyIcon;

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={cn(
        getProductBadgeBaseClasses(showOnlyIcon),
        createVariantClasses(variant, color)
      )}
    >
      {BadgeIcon && (
        <div
          className={cn(
            'w-3.5',
            'h-3.5',
            'flex',
            'items-center',
            'justify-center',
            (!showOnlyIcon || shouldUnfold) && 'mr-1.5'
          )}
        >
          <BadgeIcon
            variant={variant}
            className={cn('w-3.5', 'h-3.5', 'min-w-3.5', 'min-h-3.5')}
          />
        </div>
      )}
      {(!showOnlyIcon || shouldUnfold) && (
        <p className={cn('truncate', 'text-text-default', 'font-medium')}>
          {mapTagKeyToLabel(variant)}
        </p>
      )}
    </div>
  );
};

const badgePrimaryColors = {
  bio: 'bg-label-bio',
  vege: 'bg-label-vegetarian',
  glutenfree: 'bg-label-gluten-free',
  eco: 'bg-label-eco',
  sugarfree: 'bg-label-sugar-free',
  lactosefree: 'bg-label-lactose-free',
  vegan: 'bg-label-vegan',
  popular: 'bg-label-popular',
  new: 'bg-label-new',
  local: 'bg-label-local',
  health: 'bg-label-health',
};

const createVariantClasses = (
  variant: BadgeVariant,
  color?: 'primary' | 'promoted'
): string | null => {
  const backgroundClassName =
    color === 'promoted'
      ? 'bg-white border border-border-subtle'
      : badgePrimaryColors[variant];

  if (!backgroundClassName) {
    throw new Error(`Badge variant ${variant} is not supported`);
  }
  return backgroundClassName;
};

const getProductBadgeBaseClasses = (isIconOnly?: boolean) =>
  cn(
    isIconOnly ? cn('justify-center', 'p-[5px]') : cn('px-2.5', 'py-1'),
    'rounded-full',
    'text-xs',
    'box-content',
    'font-medium',
    'flex',
    'items-center',
    'max-w-[191px]'
  );

export const useMapTagKeyToLabel = () => {
  const { t } = useTranslation('product');

  return (variant: string): string | undefined => {
    switch (variant) {
      case 'bio':
        return t('Bio');
      case 'vege':
        return t('Vegetarian');
      case 'glutenfree':
        return t('Gluten-free');
      case 'eco':
        return t('Eco');
      case 'sugarfree':
        return t('Sugar-free');
      case 'lactosefree':
        return t('Lactose-free');
      case 'vegan':
        return t('Vegan');
      case 'popular':
        return t('Popular');
      case 'new':
        return t('New');
      case 'local':
        return t('Local');
      case 'health':
        return t('Health');
      default: {
        throw Error(`Badge variant ${variant} is not supported`);
      }
    }
  };
};
