import { Screens } from '../types/Screens';

export const mapRouteToScreen = (route: string): Screens => {
  if (route.includes('categories')) return 'Categories';
  if (route.includes('product')) return 'Product';
  if (route.includes('promotions')) return 'Promotions';
  if (route.includes('cart') || route.includes('cartSidebar=open')) {
    return 'Cart';
  }
  if (route.includes('favorites')) return 'Favorites';
  if (route.includes('search')) return 'Search';
  return 'Home';
};
